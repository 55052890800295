<template>
  <div>

    <div class="center mb">
      <img src="../../assets/images/art706.png">

      <div class="title1">{{$t("welcome2.title1")}}</div>
      <div class="title2">{{$t("welcome2.title2")}}</div>
    </div>

    <div class="text1 mb">{{$t("welcome2.content1")}}</div>

    <ui-button class="btn">{{$t('welcome2.apply')}}</ui-button>

    <copyright></copyright>

  </div>
</template>

<script>
  import UiButton from '@/components/ui/UiButton';
  import Copyright from '@/components/Copyright';

  export default {
    name: 'welcome2',
    components: { Copyright, UiButton }
  }
</script>

<style scoped>
  .btn {
    margin-top: 100px;
  }
</style>
